import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import DrawerLayout from './pages/DrawerLayout';
import Login from './Login';
import { useHistory } from 'react-router-dom';
import { StringMappingType } from 'typescript';
import { addSeconds, parseJSON } from 'date-fns'
import axios from 'axios';

function App() {
    return (
        <div className="App">
            <ProvideAuth>
                <AuthSwitch />

                {/*  <DrawerLayout />*/}

            </ProvideAuth>
        </div>
    );
}

export default App;

const fakeAuth = {
    signin(p: { username: string, password: string, onSuccess: (token: ITokenData) => any }) {
        var details: { [id: string]: string } = {
            'username': p.username,
            'password': p.password
        };

        var formBody: string[] = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        fetch('/api/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formBody.join("&")
        })
            .then((r: Response) => r.json().then(data => ({ status: r.status, data })))
            .then(
                (r: { status: number, data: ITokenData | null }) => {
                    if (r.status === 200 && r.data != null) {
                        let expiresAt = addSeconds(new Date(), r.data.expires_in);
                        p.onSuccess({ ...r.data, expiresAt }); //anticipate 10 sec expiring
                    }
                }
            ).
            catch(() => console.error("Authentication ERROR"));

        //setTimeout(cb, 100); // fake async
    }
    //,
    //signout(cb: () => void) {
    //    fakeAuth.isAuthenticated = false;
    //    setTimeout(cb, 100);
    //}
};

const authContext = createContext<null | IAuthProvider>(null);
function ProvideAuth({ children }: { children: ReactNode }) {
    const auth = useProviderAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export function useAuth() {
    return useContext(authContext);
}
export function useProviderAuth(): IAuthProvider {
    const [userData, setUserData] = useState<null | IUserData>(() => {
        let Tmp: any = getLocalStorage("user", null);
        if (Tmp) Tmp.expiresAt = parseJSON(Tmp.expiresAt);
        axios.defaults.headers.common['Authorization'] = "Bearer " + Tmp?.access_token;
        return Tmp
    });
    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = "Bearer " + userData?.access_token;
        setLocalStorage("user", userData);
    }, [userData]);
    let token: string | null = null;
    const signin = (p: { username: string, password: string, cb: () => any }) => {
        return fakeAuth.signin({
            username: p.username,
            password: p.password,
            onSuccess: (tokenData: ITokenData) => {
                setUserData({ ...tokenData, username: p.username });
                p.cb();
            }
        });
    };
    let a = 1;
    //(userData !== null && userData?.token !== null && userData.expiresAt < new Date())
    const isAuthenticated = () => {
        return userData !== null && userData?.access_token !== null && (userData.expiresAt ?? new Date()) > new Date();
    }
    const signout = (cb: () => any) => {
        setUserData(null);
        //return fakeAuth.signout(() => {
        //    setUserData(null);
        //    cb();
        //});
    };

    return {
        userData,
        signin,
        isAuthenticated,
        signout
    };
}
function AuthSwitch() {
    let history = useHistory();
    let auth = useAuth();
    return auth?.isAuthenticated() ? (<DrawerLayout />) : (<Login />);
}

export interface IAuthProvider {
    userData: null | IUserData;
    // token: ITokenData | null;
    isAuthenticated: () => boolean,
    signin: (par: { username: string, password: string, cb: () => any }) => void;
    signout: (cb: () => void) => void;
}

export interface IUserData extends ITokenData {
    username: string;
}

export interface ITokenData {
    access_token: string;
    expires_in: number;
    expiresAt?: Date;
}



function setLocalStorage<T>(key: string, value: T) {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        console.error("Error writing localstorage", e);
        // catch possible errors:
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
    }
}

function getLocalStorage<T>(key: string, initialValue: T) {
    try {
        const value = window.localStorage.getItem(key);
        return value ? JSON.parse(value) : initialValue;
    } catch (e) {
        // if error, return initial value
        return initialValue;
    }
}

//https://stackoverflow.com/questions/43051291/attach-authorization-header-for-all-axios-requests
//axios.interceptors.request.use(config => {
//    // log a message before any HTTP request is sent
//    console.log('Request was sent');
//    config.headers["Authorization"] = "Bearer " + token;
//    return config;
//});