import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { IconButton, Tooltip, Button, Typography, CardContent, CardActions, Card } from '@material-ui/core';
import { PlayArrow, Stop, Refresh } from '@material-ui/icons';
import { format } from 'date-fns';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));
export default function Dashboard() {
    const [dashData, setData] = useState<IDashdata | null>();
    const classes = useStyles();
    let loadData = () => {
        axios.get('/api/dashboard')
            .then((response: any) => {
                response["updateAt"] = new Date();
                setData(response.data);
            });
    }

    let handleCommand = (par: string) => {
        axios.put('/api/dashboard/syncservice', par)
            .then((response: any) => {
                response["updateAt"] = new Date();
                setData(response.data);
            });
    }
    useEffect(() => loadData(), []);
    const statusColor: "primary" | "error" = dashData?.status === 'Running' ? 'primary' : 'error';
    let statoCtrl = null;
    if (!dashData?.licenseValid) {
        statoCtrl = (<Tooltip title={dashData?.licenseError ?? ""}><span>No!</span></Tooltip>);
    }
    else {
        statoCtrl = (<span>Ok</span>);
    }

    if (dashData)
        return (
            <>
                <Card className={classes.root}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography component="h5" variant="h5">
                                Servizio sincronizzazione
          </Typography>
                            <Typography variant="subtitle1" color={statusColor}>
                                {dashData?.serviceName}
                            </Typography>
                        </CardContent>
                        <div className={classes.controls}>

                            <IconButton aria-label="play" onClick={() => handleCommand("stop")} >
                                <PlayArrow className={classes.playIcon} />
                            </IconButton>
                            <IconButton aria-label="Stop" onClick={() => handleCommand("start")} >
                                <Stop className={classes.playIcon} />
                            </IconButton>
                        </div>
                    </div>
                    <CardContent >
                        <Typography component="h5" variant="h5">
                            Stato
                        </Typography>
                        <Typography variant="subtitle1" color={statusColor}>
                            {dashData?.status}
                            {/*{format(dashData.updateAt, "HH:mm:ss")}*/}
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.root}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography component="h5" variant="h5">Licenza</Typography>
                            <Typography variant="subtitle1" >
                                {dashData?.licenseFile}
                            </Typography>
                        </CardContent>
                    </div>
                    <CardContent >
                        <Typography component="h5" variant="h5">
                            Valida
                        </Typography>
                        <Typography variant="subtitle1" color="error">
                            {statoCtrl}
                            {/*{format(dashData.updateAt, "HH:mm:ss")}*/}
                        </Typography>
                    </CardContent>
                </Card>
                <Button
                    variant="contained"

                    color="secondary"
                    startIcon={<Refresh />}
                    onClick={loadData}
                >Aggiorna</Button>
            </>
        );
    else
        return (<Button
            variant="contained"

            color="secondary"
            startIcon={<Refresh />}
            onClick={loadData}
        >Aggiorna</Button>);
}


export interface IDashdata {
    licenseFile: string;
    licenseError: string;
    serviceName: string;
    status: string;
    withError: string;
    updateAt: Date;
    licenseValid: boolean;
}