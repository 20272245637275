import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Input, TextField, Button, Select, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, InputLabel, FormControl, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { Delete, Settings, Error, Smartphone, Check, Close, Search } from '@material-ui/icons';
import { addDays, format } from 'date-fns';
import axios from 'axios';
//https://codesandbox.io/s/react-hook-form-v6-controller-qsd8r
//https://github.com/react-hook-form/react-hook-form/issues/373

export default function Interventi() {
    const [tableData, setTableData] = useState<IInterventoDto[]>([]);
    const [confirmState, setConfirmState] = useState({ open: false, title: "", content: "", currentId: -1 });
    const [dtlDownlState, setdtlDownlState] = useState<{ open: boolean, dtlData: IIntervSyncInfo[] | null, hData: { id: number, rowVersionNum: number } | null }>({ open: false, hData: null, dtlData: null });

    let loadData = (data: any) => {
        //data.idInterv = data.idInterv === "" ? null : data.idInterv;
        //data.cliente = data.cliente === "" ? null : data.cliente;
        Object.keys(data).forEach(k => data[k] = data[k] === "" ? null : data[k]);
        //data.= data.cliente === "" ? null : data.cliente;
        axios.post('/api/intervento/Search', data)
            .then((response: any) => {
                response["updateAt"] = new Date();
                setTableData(response.data);
            });
    }

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });
    const handleClickOpen = (title: string, content: string) => {
        setConfirmState({ ...confirmState, open: true, title: title, content: content });
    };
    const handleOpenDtlDownload = (cRow: IInterventoDto) => {
        setdtlDownlState({ ...dtlDownlState, open: true, dtlData: cRow.mobSyncInfo, hData: { id: cRow.id, rowVersionNum: cRow.rowVersionNum } });
    };


    const classes = useStyles();

    const rowStatus = (row: IInterventoDto): ["red" | "green" | "normal", string] => {
        if (row.chiuso) {
            if (row.chiusoDa === null)
                return ["red", "Chiuso da altro programma!"];
            else
                return ["green", "Chiuso correttamente"];
        }

        if (row.disdetto) {
            return ["normal", "Disdetto"];
        }
        if (row.annullato) {
            return ["normal", "Annullato"];
        }
        if (row.eliminato) {
            return ["normal", "Annullato"];
        }


        return ["normal", "Aperto"];
    }
    const plSyncStatus = (row: IInterventoDto): ["red" | "green" | "normal", string] => {
        if (row.plSyncStatus === null) {
            return ["normal", "In attesa"];
        }

        if (row.plSyncStatus == PlutinoExportStatus.Completed) {
            return ["green", "Completata"];
        }
        if (row.plSyncStatus == PlutinoExportStatus.Suspend4Error) {
            return ["red", "Errore"];
        }
        if (row.plSyncStatus == PlutinoExportStatus.Suspend4Print) {
            return ["normal", "Sospesa per stampa"];
        }
        return ["normal", "???"];
    }

    const handleError = (row: IInterventoDto) => {
        handleClickOpen("Errori aggiornamento Plutino", `Il sistema ha fatto N°:${row.syncAttempts} tentativi di sincronizzazione. \n L'ultimo eseguito il ${row.lastSync ? format(new Date(row.lastSync!), "dd/MM/yy HH:mm:ss") : ""} \n con errore: \n` + (row.syncMsg ?? ""));
    }

    const plSyncCont = (row: IInterventoDto, plSyncSts: any[]) => {
        if (row.plSyncStatus != null && row.plSyncStatus == PlutinoExportStatus.Suspend4Error)
            return (<Button size="small" aria-label="Error" style={{ borderColor: 'red', color: 'red' }} variant="contained"
                startIcon={<Error fontSize="small" />} onClick={() => handleError(row)}>{plSyncSts[1]}</Button>)
        else return plSyncSts[1]
    }

    return (
        <div>
            <InterventiFlt onSearchClick={loadData} />
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell title="Numero">Num</TableCell>
                            <TableCell title="Data">Data</TableCell>
                            <TableCell title="Commessa">Comm.</TableCell>
                            <TableCell title="Operatore">Oper.</TableCell>
                            {/*<TableCell>Cliente</TableCell>*/}
                            {/*<TableCell>Sede</TableCell>*/}
                            <TableCell>ChiusoIl</TableCell>
                            <TableCell>Chiusoda</TableCell>
                            <TableCell>Chiusura</TableCell>
                            <TableCell title="Sinc. rientro verso Plutino" >Sync.Plutino</TableCell>
                            <TableCell title="" >Downloads</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row) => {

                            const rst = rowStatus(row);
                            const plSyncSts = plSyncStatus(row);
                            const statStyle = { color: rst[0] };
                            const plSyncStyle = { color: plSyncSts[0] }
                            return (
                                <React.Fragment>
                                    <TableRow key={row.id} className="noBottomRow">
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.numero}</TableCell>
                                        <TableCell>{format(new Date(row.data), "dd/MM/yyyy")}</TableCell>
                                        <TableCell>{row.numeroContratto}</TableCell>
                                        <TableCell>{row.operatoreId}-{row.operatoreDesc}</TableCell>
                                        <TableCell>{row.dataChiusura ? format(new Date(row.dataChiusura), "dd/MM/yy HH:mm:ss") : ""}</TableCell>
                                        <TableCell>{row.chiusoDa}</TableCell>
                                        <TableCell style={statStyle}> {rst[1]}</TableCell>
                                        <TableCell style={plSyncStyle}>{plSyncCont(row, plSyncSts)}   </TableCell>
                                        <TableCell rowSpan={2}>
                                            <IconButton disabled={(row.mobSyncInfo?.length ?? 0) == 0} aria-label="PhoneAndroid" onClick={() => handleOpenDtlDownload(row)}  >
                                                <Smartphone fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={row.id.toString() + "_"}>
                                        <TableCell colSpan={4}><label style={{ fontWeight: "bold" }}>Cliente:</label>{row.cliente}</TableCell>
                                        <TableCell colSpan={5}><label style={{ fontWeight: "bold" }}>Sede:</label>{row.sedeId}-{row.sede}</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Conferma {... {
                state: confirmState, onClose: () => setConfirmState({ ...confirmState, open: false })

            }} >
            </Conferma>
            <DownlDtl {... {
                state: dtlDownlState, onClose: () => setdtlDownlState({ ...dtlDownlState, open: false })

            }} >
            </DownlDtl>
        </div >

    );
}

export function Conferma(props: any) {
    const handleClose = (res: boolean) => { props.onClose(res); }

    return (<Dialog
        open={props.state.open}
        onClose={handleClose}
        maxWidth='lg'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.state.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-line' }}>
                {props.state.content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleClose(true)} autoFocus>
                Ok
            </Button>
        </DialogActions>
    </Dialog>);
}


export function DownlDtl(props: any) {
    const handleClose = (res: boolean) => { props.onClose(res); }
    const bodyClass = { whiteSpace: 'pre-wrap' };
    const myStyles: CSSProperties = {
        whiteSpace: 'pre-wrap',
    }
    return (<Dialog
        open={props.state.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth='xl'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Elenco downloads interventi su telefono
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.state?.hData ?
                    (<Typography variant="h6" gutterBottom component="div">
                        Intervento {props.state?.hData.id} Versione: {props.state?.hData.rowVersionNum}
                    </Typography>)
                    :
                    (<span></span>)
                }

                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Scaricato Il</TableCell>
                            <TableCell>Vers.Intervento</TableCell>
                            <TableCell>Sessione download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.state?.dtlData?.map((row: IIntervSyncInfo) => {

                            return (
                                <TableRow key={row.mobileKey} >
                                    <TableCell>
                                        {
                                            row.lastRowVersionNum != (props.state?.hData?.rowVersionNum ?? 0) ?
                                                (<Close fontSize="small" color='error' />)
                                                :
                                                (<Check fontSize="small" style={{ color: 'green' }} />)
                                        }
                                    </TableCell>
                                    <TableCell>{row.mobileKey}</TableCell>
                                    <TableCell>{format(new Date(row.downloadAt), "dd/MM/yy HH:mm:ss")}</TableCell>
                                    <TableCell style={{ color: row.lastRowVersionNum != (props.state?.hData?.rowVersionNum ?? 0) ? 'red' : '' }}>{row.lastRowVersionNum}</TableCell>
                                    <TableCell>{row.downloadWithSession}</TableCell>
                                </TableRow>

                            )
                        })}
                    </TableBody>
                </Table>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleClose(true)} autoFocus>
                Ok
            </Button>
        </DialogActions>
    </Dialog >);
}
export function InterventiFlt(props: any) {

    const formStyle = {
        select: { height: '48px' },
        numberSmal: { width: '100px' }
    };
    const refSubmitButtom = useRef<HTMLButtonElement>(null);
    const { register, handleSubmit, watch, formState: { errors }, control } = useForm<IFilterValues>(
        {
            defaultValues: { idInterv: null, da: format(addDays(new Date(), -60), "yyyy-MM-dd"), a: format(new Date(), "yyyy-MM-dd"), statoChiusura: 0, statoAggPlutino: 0 }
        });
    const onSubmit = (data: any) => {
        props.onSearchClick(data);
    };

    useEffect(() => { refSubmitButtom?.current?.click(); }, []);
    //console.log(watch());
    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            {/* register your input into the hook by invoking the "register" function */}
            {/*<input type="number" {...register("commessa")} id="commessa" placeholder="Commessa" />*/}
            <Controller
                name="idInterv"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field}
                    placeholder="Int."
                    type="Number"
                    label="Intervento"
                    title="Id intervento"
                    style={formStyle.numberSmal}
                />}
            />
            <Controller
                name="cliente"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} placeholder="cliente" label="Cliente" />}
            />
            {/*<Controller as={TextField} name="cliente" control={control} />*/}

            <Controller
                name="da"
                control={control}
                defaultValue=""

                render={({ field }) => <TextField {...field} type="date" label="dal" />}
            />
            <Controller
                name="a"
                control={control}
                defaultValue=""
                render={({ field }) =>
                    <TextField {...field} type="date" label="al" />
                }
            />
            <Controller
                render={({ field }) =>
                    <FormControl style={{ width: '120px', textAlign: 'left' }}>
                        <InputLabel id="demo-simple-select-label">Chiusura</InputLabel>
                        <Select label="Chiusura" {...field}   >
                            <MenuItem value={0}>Tutti</MenuItem>
                            <MenuItem value={1}>Aperti</MenuItem>
                            <MenuItem value={2}>Chiusi</MenuItem>
                        </Select>
                    </FormControl>
                }
                name="statoChiusura"
                control={control}
            />
            <Controller
                render={({ field }) =>
                    <FormControl style={{ width: '120px', textAlign: 'left' }}  >
                        <InputLabel id="demo-simple-select-label" >Agg.Plutino</InputLabel>
                        <Select label="Agg.Plutino" {...field}   >
                            <MenuItem value={0}>Tutti</MenuItem>
                            <MenuItem value={1}>In Attesa</MenuItem>
                            <MenuItem value={2}>Con errore</MenuItem>
                            <MenuItem value={3}>Completata</MenuItem>
                        </Select>
                    </FormControl>
                }
                name="statoAggPlutino"
                control={control}
            />

            <Button type="submit" ref={refSubmitButtom} variant="contained" color="primary" endIcon={<Search />} style={{ marginLeft: '30px' }} >Cerca</Button>
        </form>
    );
}

interface IFilterValues {
    commessa: string | null;
    da: string;
    a: string;
    operatore: string | null;
    cliente: string | null;
    idInterv: string | null;
    statoChiusura: number;
    statoAggPlutino: number
}

export enum PlutinoExportStatus {
    Completed,
    Suspend4Print,
    Suspend4Error
}

export interface IInterventoDto {
    id: number;
    operatoreId: number | null;
    operatoreDesc: string | null;
    cliente: string;
    numeroContratto: number;
    dataContratto: Date;
    noteChiusura: string;
    numero: number;
    chiuso: boolean;
    disdetto: boolean;
    annullato: boolean;
    eliminato: boolean;
    riassegnato: boolean;
    sede: string;
    tipoSede: string;
    sedeId: number;
    withMonitor: boolean;
    data: Date;
    totDownloaded: number;
    servizi: any[];
    locali: any[];
    needGps: boolean;
    integrazioni?: any;
    allegati?: any;
    rowVersionNum: number;
    installazioniIds: any[];
    totInstallazioni: number;
    totRilevamenti: number;
    rilevamentiKeys?: any;
    nuoveInstallazioniKeys?: any;
    key: string;
    dataChiusura?: Date;
    chiusoDa?: number;
    plSyncStatus?: PlutinoExportStatus;
    syncMsg?: string;
    syncAttempts: number;
    lastSync: string | null; //This is a date
    mobSyncInfo: IIntervSyncInfo[] | null;
}

export interface IIntervSyncInfo {
    interventoId: number;
    mobileKey: string;
    lastRowVersion: string;
    downloadAt: Date;
    downloadWithSession: string;
    lastRowVersionNum: number;
}
