import axios from "axios";
import React, { useEffect, useState, CSSProperties } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Input, TextField, Button, Select, MenuItem, IconButton, makeStyles, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, FormControl } from '@material-ui/core';
import { Delete, Settings, Sync, Close, Check } from '@material-ui/icons';
import { format } from "date-fns";
//https://youtu.be/tC6DnUy4TvE?t=6863

const useStyles = makeStyles((theme) => ({
    margin: { margin: theme.spacing(1), },
    extendedIcon: { marginRight: theme.spacing(1), },
}));

export default function Utenti() {
    const classes = useStyles();
    const [confirmState, setConfirmState] = useState({ open: false, title: "", content: "", currentId: -1 });
    const [settingsState, setSettingsState] = useState<ISettingState>({ open: false, title: "", currentId: -1, operatori: [], selOp: -1 });
    const [tableData, setTableData] = useState<IUtenteDto[]>([]);
    const [usrSyncState, setUsrSyncState] = useState<{ open: boolean, items: ISyncSession[] | null, mobKey: string | null }>({ open: false, items: null, mobKey: null });

    const confirm = {
        state: confirmState, onClose: (ret: boolean) => {
            setConfirmState({ ...confirmState, open: false });
            if (ret)
                axios.delete(`/api/users/${confirmState.currentId}`)
                    .then((response: any) => {
                        if (response?.data !== null && "isMessage" in response.data) {
                            alert(response.data.message);
                        }
                        loadData();
                    });
        }
    };
    const settingsProps = {
        state: settingsState,
        onChange: (newVal: number | null) => setSettingsState({ ...settingsState, selOp: newVal }),
        onClose: (ret: boolean) => {
            setSettingsState({ ...settingsState, open: false });
            if (ret)
                axios.put(`/api/users`, { id: settingsState.currentId, operatoreId: settingsState.selOp })
                    .then((response: any) => {
                        if (response?.data !== null && "isMessage" in response.data) {
                            alert(response.data.message);
                        }
                        loadData();
                    });
        },
    };
    const handleDelete = (id: number) => {
        setConfirmState({
            ...confirmState, open: true, title: "Attenzione!", currentId: id,
            content: "Eliminando l'account l'utente non potrà più accedere quindi i dati sul telefono saranno persi.\nAssicurati di fare un backup dei dati del telefono di cui stai eliminando l'accesso per ogni eventualità "
        });
    }
    const handleLastSyncClk = (mobKey: string) => {
        axios.get(`/api/SyncSession/GetFor/${mobKey}`)
            .then((response: any) => {
                if (response?.data !== null && "isMessage" in response.data) {
                    alert(response.data.message);
                }
                setUsrSyncState({ open: true, items: response.data, mobKey: mobKey });
            });
    }
    const handleSetting = (user: IUtenteDto) => {
        axios.get(`/api/users/GetOperatoriFor/${user.id}`)
            .then((response: any) => {
                setSettingsState({ open: true, title: `Modifica utente ${user.userName}`, currentId: user.id, operatori: response.data, selOp: user.operatoreId });
            });
    }

    let loadData = () => {
        axios.get('/api/users')
            .then((response: any) => {
                setTableData(response.data);
            });
    }
    useEffect(() => loadData(), []);
    return (
        <div>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell title="nome">Username</TableCell>
                            <TableCell>Operatore</TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row) => {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.userName}</TableCell>
                                    <TableCell>{row.operatoreId}-{row.operatore}</TableCell>
                                    <TableCell>
                                        <IconButton aria-label="settings" className={classes.margin} onClick={() => handleSetting(row)}>
                                            <Settings fontSize="small" />
                                        </IconButton>
                                        <IconButton aria-label="delete" className={classes.margin} onClick={() => handleDelete(row.id)}>
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell><Button title="Sincronizzazioni telefono" startIcon={<Sync fontSize="small" />} onClick={() => handleLastSyncClk(row.userName)} >Ultima sync.Tel</Button></TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Conferma {...confirm} >
            </Conferma>
            <UsrSetting {...settingsProps} >
            </UsrSetting>
            <SyncStates {...{ state: usrSyncState, onClose: () => setUsrSyncState({ ...usrSyncState, open: false }) }}>
            </SyncStates>
        </div >);
}


export function UsrSetting(props: {
    state: ISettingState, onClose: (r: boolean) => void,
    onChange: (newVal: number | null) => void
}) {
    const handleClose = (res: boolean) => { props.onClose(res); }
    var selStyle = {
        width: '100%',
    };

    return (<Dialog
        open={props.state.open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{props.state.title}</DialogTitle>
        <DialogContent>
            <form>
                <FormControl style={selStyle}>
                    <Select
                        style={selStyle}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={props.state.selOp}
                        onChange={(event: any) => props.onChange(event.target.value)}
                    >
                        {props.state.operatori.map((row) => {
                            return (
                                <MenuItem value={row.id} key={row.id}>{row.id}-{row.descrizione}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </form>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
                Annulla
            </Button>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                Salva
            </Button>
        </DialogActions>
    </Dialog>);
}

export function Conferma(props: any) {
    const handleClose = (res: boolean) => { props.onClose(res); }
    const bodyClass = { whiteSpace: 'pre-wrap' };
    const myStyles: CSSProperties = {
        whiteSpace: 'pre-wrap',
    }
    return (<Dialog
        open={props.state.open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{props.state.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div style={myStyles} >
                    {props.state.content}
                </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
                Annulla
            </Button>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                Conferma
            </Button>
        </DialogActions>
    </Dialog>);
}

export function SyncStates(props: any) {
    const handleClose = (res: boolean) => { props.onClose(res); }
    const bodyClass = { whiteSpace: 'pre-wrap' };
    const myStyles: CSSProperties = {
        whiteSpace: 'pre-wrap',
    }
    return (<Dialog
        open={props.state.open}
        onClose={() => handleClose(false)}
        maxWidth='lg'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Ultime sinctonizzazioni di {props.state.mobKey}</DialogTitle>
        <DialogContent>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Sessione</TableCell>
                        <TableCell>Inizio</TableCell>
                        <TableCell>Fine</TableCell>
                        <TableCell>App.Ver</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.state?.items?.map((row: ISyncSession) => {

                        return (
                            <TableRow key={row.sessionId} >
                                <TableCell>
                                    {
                                        row.end == null ?
                                            (<Close fontSize="small" color='error' />)
                                            :
                                            (<Check fontSize="small" style={{ color: 'green' }} />)
                                    }
                                </TableCell>
                                <TableCell>{row.sessionId}</TableCell>
                                <TableCell>{format(new Date(row.begin), "dd/MM/yy HH:mm:ss")}</TableCell>
                                <TableCell style={{ color: row.end == null ? 'red' : '' }}>{row.end ? format(new Date(row.end), "dd/MM/yy HH:mm:ss") : "X"}</TableCell>
                                <TableCell>{ row.appVer }</TableCell>
                            </TableRow>

                        )
                    })}
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                Ok
            </Button>
        </DialogActions>
    </Dialog>);
}

export interface IUtenteDto {
    id: number; userName: string;
    operatoreId: number | null; operatore: string | null;
}

export interface IIdDescription {
    id: number;
    descrizione: string;
}

export interface ISettingState {
    open: boolean;
    title: string;
    currentId: number;
    operatori: IIdDescription[];
    selOp: number | null;

}



export interface ISyncSession {
    mobileKey: string;
    sessionId: string;
    begin: string;
    end: string | null;
    appVer: string;
}